import { useState, useContext } from "react";
import Context from "../contexts/AuthContext";

export function useFetchErrorHandling() {
  const { setShowErrorConfirmationPopUp, setShowErrorMessage } =
    useContext(Context);

  function handleFetchError(err: Error) {
    console.error(err);
    setShowErrorMessage(`${err.message}`);
    setShowErrorConfirmationPopUp(true);
  }

  return { handleFetchError };
}
