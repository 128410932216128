import spinner from "../../../../src/img/loader/Spinner-1s-200px.svg";

const LoadingSpinner = () => {
  return (
    <div className="loaderSpinnerPagesContainer">
      <div className="loaderSpinnerPages">
        <img alt="Loading Spinner" src={spinner} />
      </div>
    </div>
  );
};

export default LoadingSpinner;
