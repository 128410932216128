import {
  GetSingleProduct,
  CartTS,
  ProductOverview,
} from "../../fetchRequests/products/ts/getTS";

export const addGlobalProductToCart = (
  newProduct: GetSingleProduct,
  cart: CartTS | null
): CartTS => {
  if (!newProduct || typeof newProduct.price !== "string") {
    // Handle invalid input by throwing an error or returning an appropriate value
    throw new Error(
      "Invalid input. Please provide valid cart and newProduct objects."
    );
  }

  // Create a shallow copy of the cart to maintain immutability
  // OR if this is the first time create an empty cart
  let updatedCart;
  if (cart && cart !== undefined) {
    updatedCart = { ...cart };
  } else {
    updatedCart = {
      quantity: 0,
      total: "0.00",
      products: [],
    };
  }

  // Find the index of the new product in the existing products array
  const existingProductIndex = updatedCart.products.findIndex(
    (existingProduct) =>
      existingProduct.global_product_id === newProduct.global_product_id
  );

  // Update the total and quantity for the entire cart
  const newProductPrice = parseFloat(newProduct.price);

  if (isNaN(newProductPrice)) {
    // Handle invalid price by throwing an error or returning an appropriate value
    throw new Error(
      "Invalid price. Please provide a valid numeric price in the newProduct object."
    );
  }

  updatedCart.total = (parseFloat(updatedCart.total) + newProductPrice).toFixed(
    2
  );
  updatedCart.quantity += 1;

  if (existingProductIndex >= 0) {
    // If the product already exists in the cart, update its quantity and total
    const existingProduct = updatedCart.products[existingProductIndex];
    existingProduct.total = (
      parseFloat(existingProduct.total) + newProductPrice
    ).toFixed(2);
    existingProduct.quantity += 1;
  } else {
    // If the product is not in the cart, add a new product overview
    const newProductOverview: ProductOverview = {
      global_product_id: newProduct.global_product_id,
      price: newProductPrice.toFixed(2),
      quantity: 1,
      total: newProductPrice.toFixed(2),
      category_slug: newProduct.category_slug,
      product_slug: newProduct.product_slug,
      image_url: newProduct.image_url || "",
      product_title: newProduct.product_title,
    };
    updatedCart.products.push(newProductOverview);
  }

  return updatedCart;
};

export const removeGlobalProductFromCart = (
  cart: CartTS,
  global_product_id: string
) => {
  if (!cart || !global_product_id || typeof global_product_id !== "string") {
    // Handle invalid input by throwing an error or returning an appropriate value
    throw new Error("Invalid input. Please provide valid cart and product ID.");
  }

  // Create a shallow copy of the cart to maintain immutability
  const updatedCart: CartTS = { ...cart };

  // Find the index of the product in the existing products array
  const existingProductIndex = updatedCart.products.findIndex(
    (existingProduct: ProductOverview) =>
      existingProduct.global_product_id === global_product_id
  );

  if (existingProductIndex < 0) {
    throw new Error("Product ID not found in cart.");
  }

  const removedProduct = updatedCart.products[existingProductIndex];

  // Update the total and quantity for the entire cart
  updatedCart.total = (
    parseFloat(updatedCart.total) -
    parseFloat(removedProduct.total) / removedProduct.quantity
  ).toFixed(2);
  updatedCart.quantity -= 1;

  if (removedProduct.quantity === 1) {
    // If there's only one quantity of the product, remove the entire element
    updatedCart.products.splice(existingProductIndex, 1);
  } else {
    // If there are multiple quantities, decrement the quantity and update the total
    removedProduct.total = (
      parseFloat(removedProduct.total) -
      parseFloat(removedProduct.total) / removedProduct.quantity
    ).toFixed(2);
    removedProduct.quantity -= 1;
  }

  return updatedCart;
};

export const updateProductCartQuantity = (
  cart: CartTS,
  global_product_id: string,
  new_quantity: number
) => {
  if (
    !cart ||
    !global_product_id ||
    !new_quantity ||
    typeof global_product_id !== "string" ||
    Number.isNaN(new_quantity) || // Check for a valid number
    new_quantity <= 0 // Check for a positive number
  ) {
    // Handle invalid input by throwing an error or returning an appropriate value
    throw new Error(
      "Invalid input. Please provide a valid cart, product ID, and positive quantity."
    );
  }

  // Create a shallow copy of the cart to maintain immutability
  const updatedCart: CartTS = { ...cart };

  const existingProductIndex = updatedCart.products.findIndex(
    (existingProduct: ProductOverview) =>
      existingProduct.global_product_id === global_product_id
  );

  if (existingProductIndex < 0) {
    throw new Error("Product ID not found in cart.");
  }

  const productToUpdate = updatedCart.products[existingProductIndex];

  updatedCart.total = (
    parseFloat(updatedCart.total) -
    parseFloat(productToUpdate.total) +
    parseFloat(productToUpdate.price) * new_quantity
  ).toFixed(2);
  updatedCart.quantity =
    updatedCart.quantity - productToUpdate.quantity + new_quantity;

  productToUpdate.total = `${(
    parseFloat(productToUpdate.price) * new_quantity
  ).toFixed(2)}`;
  productToUpdate.quantity = new_quantity;

  return updatedCart;
};
