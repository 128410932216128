import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ConstProvider } from "../contexts/AuthContext";
import RequireAuth from "./global/requireAuth/RequireAuth";
import RequireAdminAuth from "./global/requireAuth/RequireAdminAuth";
import { Helmet } from "react-helmet";
import "../css/imports.css";
import ReactGA from "react-ga";
import { CookiesProvider } from "react-cookie";
import AuthProvider from "../components/global/authProvider/AuthProvider";
import spinner from "../img/loader/Spinner-1s-200px.svg";

ReactGA.initialize("UA-124627850-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = React.lazy(() => import("./pages/home/Home"));

// Admin Only
const AdminPanel = React.lazy(() => import("./pages/admin/AdminPanel"));

const TrainerReviewAdmin = React.lazy(
  () => import("./pages/admin/trainerReview/TrainerReview")
);

const FeedbackBoard = React.lazy(
  () => import("./pages/admin/feedbackBoard/FeedbackBoard")
);

const UsersBoard = React.lazy(
  () => import("./pages/admin/usersBoard/UsersBoard")
);

// Normal
const ScrollToTopGlobalButton = React.lazy(
  () =>
    import("./global/buttons/ScrollToTopGlobalButton/ScrollToTopGlobalButton")
);

/* const CompanyFilter = React.lazy(
  () => import("./pages/company_sub/companyFilter/CompanyFilter")
); */

const TrainerSearchFullPage = React.lazy(
  () =>
    import("./pages/company_sub/trainerSearchFullPage/TrainerSearchFullPage")
);

const CompanyProfile = React.lazy(
  () => import("./pages/company_sub/companyProfile/CompanyProfile")
);

const CompanyAddNew = React.lazy(
  () => import("./pages/company_sub/companyAddNew/CompanyAddNew")
);

const RequireCredentialVerification = React.lazy(
  () => import("./global/requireAuth/RequireCredentialVerification")
);

const RequireTestingAuth = React.lazy(
  () => import("./global/requireAuth/RequireTestingAuth")
);

const MainVideoPage = React.lazy(
  () => import("./pages/user_sub/videoMeeting/MainVideoPage")
);

/* const CredentialVerification = React.lazy(
  () =>
    import(
      "./pages/company_sub/companyAddNew/CredentialVerification/CredentialVerification"
    )
); */

const ZoomAuthentication = React.lazy(
  () => import("./pages/zoom/zoomAuthentication/ZoomAuthentication")
);

const CreateClientForm = React.lazy(
  () =>
    import("./pages/company_sub/clientForms/createClientForm/CreateClientForm")
);

const ClientForms = React.lazy(
  () => import("./pages/company_sub/clientForms/ClientForms")
);

const SearchPopUp = React.lazy(() => import("./global/search/SearchPopUp"));
const Header = React.lazy(() => import("./global/header/Header"));
const SideNavMobile = React.lazy(
  () => import("./global/header/components/SideNavMobile")
);

/* const UploadProfileImage = React.lazy(
  () =>
    import(
      "./pages/user_sub/profile/profileheader/profileImage/UploadProfileImage"
    )
); */
/* const UploadBannerImage = React.lazy(
  () =>
    import(
      "./pages/user_sub/profile/profileheader/uploadbannerImage/UploadBannerImage"
    )
); */

const Login = React.lazy(() => import("./pages/authorisation_sub/login/Login"));
const SessionExpired = React.lazy(
  () => import("./pages/authorisation_sub/sessionexpired/SessionExpired")
);

const Register = React.lazy(
  () => import("./pages/authorisation_sub/register/Register")
);
const ResetPassword = React.lazy(
  () => import("./pages/authorisation_sub/resetPassword/ResetPassword")
);
const ForgottenPassword = React.lazy(
  () => import("./pages/authorisation_sub/forgottenPassword/ForgottenPassword")
);
const EmailVerification = React.lazy(
  () => import("./pages/authorisation_sub/emailverification/EmailVerification")
);

/* const Overview = React.lazy(
  () => import("./pages/controlPanel_sub/pageSectionTabs/overview/Overview")
); */

const ControlPanel = React.lazy(
  () => import("./pages/controlPanel_sub/ControlPanel")
);

/* const MyEvents = React.lazy(
  () => import("./pages/exerciseClasses_sub/myEvents/MyEvents")
); */

const ExerciseClassMetrics = React.lazy(
  () =>
    import(
      "./pages/exerciseClasses_sub/exerciseClassMetrics/ExerciseClassMetrics"
    )
);

const ClientMeta = React.lazy(
  () => import("./pages/company_sub/clientMeta/ClientMeta")
);

/* const MyProducts = React.lazy(
  () => import("./pages/shop_sub/myProducts/MyProducts")
); */

const CreatedStripeAccount = React.lazy(
  () => import("./pages/payments/createdStripeAccount/CreatedStripeAccount")
);

const MyProgress = React.lazy(
  () => import("./pages/company_sub/myProgress/MyProgress")
);
const Profile = React.lazy(() => import("./pages/user_sub/profile/Profile"));

/* const ProfileSentPendingContacts = React.lazy(
  () =>
    import(
      "./pages/controlPanel_sub/pageSectionTabs/profileSentPendingContacts/ProfileSentPendingContacts"
    )
); */
const Profiles = React.lazy(
  () => import("./pages/user_sub/profileSearchPage/ProfileSearchPage")
);
const Calendar = React.lazy(
  () => import("./pages/calendar/userCalendar/Calendar")
);

const ExercisePrograms = React.lazy(
  () => import("./pages/createProgram/ExerciseProgramList")
);

const ProgramTemplate = React.lazy(
  () => import("./pages/createProgram/view/ProgramTemplate2")
);

const ProgramTemplateCreateNew = React.lazy(
  () => import("./pages/createProgram/create/ProgramTemplateCreateNew")
);

const EditExerciseProgram = React.lazy(
  () => import("./pages/createProgram/edit/EditExerciseProgram")
);

const CalendarOptions = React.lazy(
  () => import("./pages/calendar/userCalendar/CalendarOptions")
);

const RecurringCalendarBreaks = React.lazy(
  () => import("./pages/calendar/userCalendar/RecurringCalendarBreaks")
);

const EditRecurringDaysOff = React.lazy(
  () => import("./pages/calendar/userCalendar/EditRecurringDaysOff")
);

/* const CompanyCalendar = React.lazy(
  () => import("./pages/calendar/companyCalendar/CompanyCalendar")
); */
const Events = React.lazy(
  () => import("./pages/exerciseClasses_sub/events/Events")
);
const Event = React.lazy(
  () => import("./pages/exerciseClasses_sub/event/Event")
);
const AddNewEvent = React.lazy(
  () => import("./pages/exerciseClasses_sub/addEvent/AddNewEvent")
);
/* const ImageSliderFullScreen = React.lazy(
  () => import("./global/galleries/ImageSliderFullScreen")
); */
const Messages = React.lazy(() => import("./pages/user_sub/messages/Messages"));

const ExerciseDatabase = React.lazy(
  () => import("./pages/exercises_sub/exerciseDatabase/ExerciseDatabase")
);
const Exercise = React.lazy(
  () => import("./pages/exercises_sub/exercise/Exercise")
);
const AnteriorMuscles = React.lazy(
  () => import("./pages/exercises_sub/exercise/AnteriorMuscles")
);
const AnteriorMuscleSingle = React.lazy(
  () => import("./pages/exercises_sub/exercise/AnteriorMusclesSingle")
);
const PosteriorMuscles = React.lazy(
  () => import("./pages/exercises_sub/exercise/PosteriorMuscles")
);
const PosteriorMuscleSingle = React.lazy(
  () => import("./pages/exercises_sub/exercise/PosteriorMusclesSingle")
);
const HumanDiagram = React.lazy(
  () => import("./pages/exercises_sub/humanDiagram/HumanDiagram")
);
const BodyFatPercentageCalculator = React.lazy(
  () =>
    import(
      "./pages/tools_sub/bodyFatPercentageCalculator/BodyFatPercentageCalculator"
    )
);
const IntervalTimer = React.lazy(
  () => import("./pages/tools_sub/intervalTimer/IntervalTimer")
);
const ShopOverview = React.lazy(
  () => import("./pages/shop_sub/shopOverview/ShopOverview")
);
const AddNewProduct = React.lazy(
  () => import("./pages/shop_sub/addNewProduct/AddNewProduct")
);
const Cart = React.lazy(() => import("./pages/shop_sub/cart/Cart"));
// Comment out below
const Checkout = React.lazy(() => import("./pages/shop_sub/checkout/Checkout"));

const StripeStarter = React.lazy(
  () => import("./pages/shop_sub/stripe/StripeStarter")
);

const SuccessfulPayment = React.lazy(
  () => import("./pages/shop_sub/successfulPayment/SuccessfulPayment")
);

const IndividualPurchases = React.lazy(
  () =>
    import("./pages/payments/purchases/individualPurchase/IndividualPurchase")
);

const PurchaseHistory = React.lazy(
  () => import("./pages/shop_sub/purchaseHistory/PurchaseHistory")
);

const PurchaseHistoryProductDetails = React.lazy(
  () => import("./pages/shop_sub/purchaseHistory/PurchaseDetails")
);
const CategoryOverview = React.lazy(
  () => import("./pages/shop_sub/categoryOverview/CategoryOverview")
);
const ProductOverview = React.lazy(
  () => import("./pages/shop_sub/productOverview/ProductOverview")
);
const Articles = React.lazy(
  () => import("./pages/articles_sub/articles/Articles")
);
const AddNewArticle = React.lazy(
  () => import("./pages/articles_sub/addNewArticle/AddNewArticle")
);

const Article = React.lazy(
  () => import("./pages/articles_sub/article/Article")
);
const Feedback = React.lazy(() => import("./pages/user_sub/feedback/Feedback"));

const NewBugReportButton = React.lazy(
  () => import("./global/buttons/newBugReportButton/NewBugReportButton")
);

const Contact = React.lazy(() => import("./pages/contact/Contact"));

const CookiePolicy = React.lazy(() => import("./pages/legal_sub/CookiePolicy"));
const PrivacyPolicy = React.lazy(
  () => import("./pages/legal_sub/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(
  () => import("./pages/legal_sub/TermsAndConditions")
);
const UnauthorisedPermissions = React.lazy(
  () => import("./pages/authorisation_sub/unauthorised/UnauthorisedPermissions")
);
const CookieNotification = React.lazy(
  () => import("./pages/legal_sub/CookieNotification")
);
const UpdateConfirmationPopUp = React.lazy(
  () =>
    import("./global/confirmations/updateConfirmation/UpdateConfirmationPopUp")
);
const FeedbackNewTicket = React.lazy(
  () => import("./pages/user_sub/feedback/FeedbackNewTicket")
);
/* 
const DeleteConfirmationPopUp = React.lazy(
  () =>
    import("./global/confirmations/deleteConfirmation/DeleteConfirmationPopUp")
); */
/* 
const MyFiles = React.lazy(() => import("./pages/user_sub/myfiles/MyFiles")); */

const ProductDetailsID = React.lazy(
  () => import("./pages/shop_sub/productDetailsID/ProductDetailsID")
);

const ErrorConfirmationPopUp = React.lazy(
  () =>
    import("./global/confirmations/errorConfirmation/errorConfirmationPopUp")
);

const Footer = React.lazy(() => import("./global/footer/Footer"));
const Error404 = React.lazy(() => import("./pages/errors/Error404"));

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <CookiesProvider>
        <ConstProvider>
          <Suspense
            fallback={
              <div className="loaderSpinner">
                <img alt="Loading Spinner" src={spinner} />
              </div>
            }
          >
            <Helmet>
              <title>Urbacise</title>
              <meta name="description" content="Test 123" />
            </Helmet>

            <Header />
            <SideNavMobile />
            <div className="container">
              <AuthProvider>
                <Routes>
                  {/* Public paths */}
                  <Route path="/" element={<Home />} />
                  {/*  <Route path="/trainers-search" element={<CompanyFilter />} /> */}
                  <Route
                    path="/company/profile/:company_slug"
                    element={
                      <RequireTestingAuth>
                        <CompanyProfile />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/client-sign-up-form/:client_form_id"
                    element={<ClientForms />}
                  />
                  <Route
                    path="/events"
                    element={
                      <RequireTestingAuth>
                        <Events />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/events/:global_product_id"
                    element={
                      <RequireTestingAuth>
                        <Event />
                      </RequireTestingAuth>
                    }
                  />
                  <Route path="/exercises" element={<ExerciseDatabase />} />
                  <Route
                    path="/exercises/:exercise_slug"
                    element={<Exercise />}
                  />
                  <Route
                    path="/anterior-muscles"
                    element={<AnteriorMuscles />}
                  />
                  <Route
                    path="/anterior-muscles"
                    element={<AnteriorMuscles />}
                  />
                  <Route
                    path="/anterior-muscles/:anterior_slug"
                    element={<AnteriorMuscleSingle />}
                  />
                  <Route
                    path="/posterior-muscles"
                    element={<PosteriorMuscles />}
                  />
                  <Route
                    path="/posterior-muscles/:posterior_slug"
                    element={<PosteriorMuscleSingle />}
                  />
                  <Route path="/human-diagram" element={<HumanDiagram />} />
                  <Route
                    path="/body-fat-percentage-calculator"
                    element={<BodyFatPercentageCalculator />}
                  />
                  <Route path="/interval-timer" element={<IntervalTimer />} />
                  <Route path="/shop" element={<ShopOverview />} />
                  <Route
                    path="/cart"
                    element={
                      <RequireTestingAuth>
                        <Cart />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/shop/:category_slug"
                    element={<CategoryOverview />}
                  />
                  <Route
                    path="/shop/:category_slug/:slug"
                    element={<ProductOverview />}
                  />
                  <Route path="/articles" element={<Articles />} />
                  <Route path="/articles/:article_slug" element={<Article />} />
                  <Route
                    path="/login"
                    element={
                      <RequireTestingAuth>
                        <Login />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/session-expired"
                    element={
                      <RequireTestingAuth>
                        <SessionExpired />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/resetpassword/:reset_token"
                    element={
                      <RequireTestingAuth>
                        <ResetPassword />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/forgotpassword"
                    element={
                      <RequireTestingAuth>
                        <ForgottenPassword />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <RequireTestingAuth>
                        <Register />
                      </RequireTestingAuth>
                    }
                  />
                  <Route
                    path="/emailverification/:emailvalidation_token"
                    element={
                      <RequireTestingAuth>
                        <EmailVerification />
                      </RequireTestingAuth>
                    }
                  />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/cookie-policy" element={<CookiePolicy />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route
                    path="/unauthorised"
                    element={<UnauthorisedPermissions />}
                  />
                  <Route
                    path="/trainers-search"
                    element={
                      <RequireTestingAuth>
                        <TrainerSearchFullPage />
                      </RequireTestingAuth>
                    }
                  />
                  {/* Private paths */}
                  {/*   <Route
                    path="/register"
                    element={
                      <RequireAuth>
                        <Register />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/checkout"
                    element={
                      <RequireAuth>
                        <StripeStarter />
                      </RequireAuth>
                    }
                  />
                  // Comment out below
                  <Route
                    path="/checkout"
                    element={
                      <RequireAuth>
                        <Checkout />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/account-creation-and-connection-confirmation"
                    element={
                      <RequireAuth>
                        <CreatedStripeAccount />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/successful-payment"
                    element={
                      <RequireAuth>
                        <SuccessfulPayment />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/individual_purchase/:individual_purchases_id"
                    element={
                      <RequireAuth>
                        <IndividualPurchases />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/purchase-history"
                    element={
                      <RequireAuth>
                        <PurchaseHistory />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/purchase-history/product-details/:group_purchase_id"
                    element={
                      <RequireAuth>
                        <PurchaseHistoryProductDetails />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/control-panel"
                    element={
                      <RequireAuth>
                        <ControlPanel />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/add-new-company"
                    element={
                      <RequireAuth>
                        <RequireCredentialVerification>
                          <CompanyAddNew />
                        </RequireCredentialVerification>
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                path="/credential-verification"
                element={
                  <RequireAuth>
                    <CredentialVerification />
                  </RequireAuth>
                }
                /> */}
                  <Route
                    path="/create-client-form"
                    element={
                      <RequireAuth>
                        <CreateClientForm />
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                    path="/my-client-forms"
                    element={
                      <RequireAuth>
                        <MyClientForms />
                      </RequireAuth>
                    }
                  /> */}
                  {/*  <Route
                    path="task-manager"
                    element={
                      <RequireAuth>
                        <TaskManager />
                      </RequireAuth>
                    }
                  /> */}
                  {/*  <Route
                    path="/my-files"
                    element={
                      <RequireAuth>
                        <MyFiles />
                      </RequireAuth>
                    }
                  /> */}
                  {/*  <Route
                    path="/my-articles"
                    element={
                      <RequireAuth>
                        <MyArticles />
                      </RequireAuth>
                    }
                  /> */}
                  {/*  <Route
                path="/wysiwyg"
                element={
                  <RequireAuth>
                    <WYSIWYG />
                  </RequireAuth>
                }
                /> */}
                  <Route
                    path="/zoom-authentication"
                    element={
                      <RequireAuth>
                        <ZoomAuthentication />
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                    path="/overview"
                    element={
                      <RequireAuth>
                        <Overview />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/add-new-article"
                    element={
                      <RequireAuth>
                        <AddNewArticle />
                      </RequireAuth>
                    }
                  />
                  {/* <Route
                    path="/my-events"
                    element={
                      <RequireAuth>
                        <MyEvents />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/events/:global_product_id/metrics"
                    element={
                      <RequireAuth>
                        <ExerciseClassMetrics />
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                    path="/my-clients"
                    element={
                      <RequireAuth>
                        <MyClients />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/clientmeta/:user_slug"
                    element={
                      <RequireAuth>
                        <ClientMeta />
                      </RequireAuth>
                    }
                  />
                  {/*   <Route
                    path="/my-companies"
                    element={
                      <RequireAuth>
                        <MyCompanies />
                      </RequireAuth>
                    }
                  /> */}
                  {/*  <Route
                    path="/my-products"
                    element={
                      <RequireAuth>
                        <MyProducts />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/product-details/:product_id"
                    element={
                      <RequireAuth>
                        <ProductDetailsID />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/progress/:user_slug"
                    element={
                      <RequireAuth>
                        <MyProgress />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/add-new-product"
                    element={
                      <RequireAuth>
                        <AddNewProduct />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/profiles"
                    element={
                      <RequireAuth>
                        <Profiles />
                      </RequireAuth>
                    }
                  />
                  {/*   <Route
                    path="/company-calendar/:company_slug"
                    element={
                      <RequireAuth>
                        <CompanyCalendar />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/calendar/:profile_slug"
                    element={
                      <RequireAuth>
                        <Calendar />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-programs/:profile_slug"
                    element={
                      <RequireAuth>
                        <ExercisePrograms />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-program-view/:program_id"
                    element={
                      <RequireAuth>
                        <ProgramTemplate />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-program-create"
                    element={
                      <RequireAuth>
                        <ProgramTemplateCreateNew />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/exercise-program-edit/:program_id"
                    element={
                      <RequireAuth>
                        <EditExerciseProgram />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/calendar/options"
                    element={
                      <RequireAuth>
                        <CalendarOptions />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/calendar/edit-recurring-days-off"
                    element={
                      <RequireAuth>
                        <EditRecurringDaysOff />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/calendar/edit-recurring-breaks"
                    element={
                      <RequireAuth>
                        <RecurringCalendarBreaks />
                      </RequireAuth>
                    }
                  />
                  <Route path="/profiles/:profile_slug" element={<Profile />} />
                  <Route
                    path="events/add-event"
                    element={
                      <RequireAuth>
                        <AddNewEvent />
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                    path="/pending_contacts"
                    element={
                      <RequireAuth>
                        <ProfilePendingContacts />
                      </RequireAuth>
                    }
                  /> */}
                  {/*   <Route
                    path="/sent_contact_requests"
                    element={
                      <RequireAuth>
                        <ProfileSentPendingContacts />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/messages"
                    element={
                      <RequireAuth>
                        <Messages />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/video-meeting"
                    element={
                      <RequireAuth>
                        <MainVideoPage />
                      </RequireAuth>
                    }
                  />
                  {/*  <Route
                    path="/account-settings"
                    element={
                      <RequireAuth>
                        <AccountSettings />
                      </RequireAuth>
                    }
                  /> */}
                  {/*  <Route
                    path="/profile-settings"
                    element={
                      <RequireAuth>
                        <ProfileSettings />
                      </RequireAuth>
                    }
                  /> */}
                  <Route
                    path="/feedback"
                    element={
                      <RequireAuth>
                        <Feedback />
                      </RequireAuth>
                    }
                  />
                  {/* Admin */}
                  <Route
                    path="/admin"
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <AdminPanel />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/admin/trainer-review-admin"
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <TrainerReviewAdmin />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/admin/feedback-admin"
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <FeedbackBoard />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={
                      <RequireAuth>
                        <RequireAdminAuth>
                          <UsersBoard />
                        </RequireAdminAuth>
                      </RequireAuth>
                    }
                  />
                  {/* Catch all */}
                  <Route path="*" element={<Error404 />} />
                </Routes>
              </AuthProvider>
            </div>
            <ScrollToTopGlobalButton />
            <FeedbackNewTicket />
            <UpdateConfirmationPopUp />
            <ErrorConfirmationPopUp />
            <CookieNotification />
            {/*  <UploadProfileImage /> */}
            {/*   <UploadBannerImage /> */}
            {/*   <DeleteConfirmationPopUp /> */}
            {/* <ImageSliderFullScreen /> */}
            <SearchPopUp />
            <NewBugReportButton />
            <Footer />
          </Suspense>
        </ConstProvider>
      </CookiesProvider>
    </>
  );
};

export default App;
