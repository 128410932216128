import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import RequireAdminReject from "./RequireAdminReject";
import { ReactNode } from "react";
import { getUserIsAdmin } from "../../../fetchRequests/users/fetchRequestAuth";
import LoadingSpinner from "../loadingSpinner/LoadingSpinner";

interface Props {
  children: ReactNode;
}

const RequireAdminAuth = ({ children }: Props): JSX.Element => {
  const { cookies } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserIsAdmin(cookies.token)
      .then((response: any) => {
        setIsAdmin(response.body.admin);
      })
      .catch((err: Error) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!isAdmin) {
    return <RequireAdminReject />;
  }

  return <>{children}</>;
};

export default RequireAdminAuth;
