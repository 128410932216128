import isProduction from "../isStagingOrProduction";
import { ResponseOK } from "../../typeScriptTypes/TypeScriptTypes";
import { authFetch } from "../authFetch";

let baseUrl = "";

if (isProduction) {
  baseUrl = `${process.env.REACT_APP_PRODUCTION_BASE}/api/v1/login-session`;
} else {
  baseUrl = `${process.env.REACT_APP_LOCAL_BASE}/api/v1/login-session`;
}

// @desc Get/validate a users login session
// @route GET /api/v1/login-session/:token
// @access Private
export const getUsersLoginSessionToken = (token: string) => {
  return authFetch(`${baseUrl}/${token}`, {
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => {
    const myResponse: ResponseOK = {
      body: data,
      status: data.status,
      statusText: data.statusText,
      json: () => Promise.resolve(data),
      ok: true,
    };
    return myResponse;
  });
};
