import { useState, useContext } from "react";
import Context from "../../../../contexts/AuthContext";
import { resendEmailVerification } from "../../../../fetchRequests/users/fetchRequestAuth";
import ProcessingButton from "../../../global/buttons/processingButton/ProcessingButton";
import { useFetchErrorHandling } from "../../../../fetchRequests/useFetchErrorHandling";

const EmailAuthorised = () => {
  const { cookies, setShowErrorConfirmationPopUp, setShowErrorMessage } =
    useContext(Context);

  const { handleFetchError } = useFetchErrorHandling();

  const [resent, setResend] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClick = () => {
    setIsProcessing(true);
    resendEmailVerification(cookies.token)
      .then(() => {
        setResend(true);
      })
      .catch((err: Error) => {
        handleFetchError(err);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  };

  return (
    <div className="center80">
      <h1>
        Please check your email inbox, you need to verify your email address
        before you can view our content.
      </h1>
      <p>
        This is a securing feature to help prevent bots. You have 7 days to
        verify your email address, if after this time your email address is not
        verified your account will be deleted.
      </p>
      <p>
        If you have misplaced your verification email please request a new one
        below.
      </p>
      {!resent ? (
        <ProcessingButton
          isProcessing={isProcessing}
          buttonTitle={"Send new verification email"}
          icon={"fa fa-envelope-o"}
          onClickFnc={handleClick}
        />
      ) : (
        /*  <button className="btn primary" onClick={() => handleClick()}>
          Send new verification email
        </button> */
        <h2 className="error">
          <strong>Email has been resent</strong>
        </h2>
      )}
    </div>
  );
};

export default EmailAuthorised;
