import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { ReactNode } from "react";
import { getUsersLoginSessionToken } from "../../../fetchRequests/users/fetchRequestLoginSession";
import LoadingSpinner from "../../global/loadingSpinner/LoadingSpinner";

interface Props {
  children: ReactNode;
}

const AuthProvider = ({ children }: Props): JSX.Element => {
  const { cookies, currentLoggedIn, updateLoggedInUser } =
    useContext(AuthContext);
  const [isFetching, setIsFetching] = useState(true); // Start as true to display loading

  useEffect(() => {
    const fetchData = async () => {
      if (!currentLoggedIn && cookies.token) {
        getUsersLoginSessionToken(cookies.token)
          .then((response) => {
            updateLoggedInUser(response.body.login_session);
          })
          .catch((err: Error) => {
            console.error(err);
          })
          .finally(() => {
            setIsFetching(false); // Set to false when done, whether success or error
          });
      } else {
        setIsFetching(false); // No need to fetch, set to false immediately
      }
    };

    fetchData(); // Call the async function
  }, [cookies.token, currentLoggedIn]);

  if (isFetching) {
    return <LoadingSpinner />; // Or any loading indicator you prefer
  }

  return <>{children}</>;
};
export default AuthProvider;
