const RequireAdminReject = (): JSX.Element => {
  return (
    <div className="center80">
      <h1>Restricted Content</h1>
      <p>You're not authorised to view this content.</p>
    </div>
  );
};

export default RequireAdminReject;
