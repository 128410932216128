interface AuthFetchOptions extends RequestInit {
  token?: string; // Optional token property
}

export const authFetch = async (
  url: string,
  options: AuthFetchOptions = {}
) => {
  const headers = {
    ...options.headers,
    ...(options.token ? { Authorization: options.token } : {}),
  };

  const response = await fetch(url, {
    ...options,
    headers,
  });

  let data;

  // Handle successful 204 No Content responses
  if (response.status === 204 && response.ok) {
    return {}; // Return an empty object or any other suitable value for 204 responses
  } else {
    // For all other responses, attempt to parse the JSON
    data = await response.json();
  }

  if (!response.ok) {
    // Check if the response contains the specific 401 error from your backend
    if (
      response.status === 401 &&
      data.msg === "Access denied invalid session_id or session expired"
    ) {
      window.location.href = "/session-expired";
      throw new Error("Redirecting to login"); // Ensure that the function never returns undefined
    }

    throw new Error(data.msg || "An error occurred while fetching the data.");
  }

  return data;
};
