import { loginUserTS } from "../../../fetchRequests/users/ts/postTS";

type SetCurrentLoggedIn = (user: loginUserTS | null) => void;

export const handleLoginNewUser = (
  user: loginUserTS,
  setCurrentLoggedIn: SetCurrentLoggedIn
) => {
  try {
    if (!user || !user.token) {
      throw new Error("Invalid user object or missing token.");
    }

    // Set the current user upon successful login
    setCurrentLoggedIn(user);
  } catch (error) {
    console.error("Error during user login:", error);
    // Handle the error appropriately
  }
};
